.homepage {
  min-height: 473px;
  &.new-solution {
    @media (min-width: 1200px) {
      background: url(../../assets/images/bg-homepage-new-solution.jpg) no-repeat center top;
    }

    .main-title {
      text-align: center;
      text-transform: uppercase;
      font-size: 2rem;
      @media (min-width: 1024px) {
        font-size: 2.5rem;
      }
      padding: 1rem 0;
      color: $gray-700;
      font-weight: 300;

      strong {
        font-weight: 700;
        color: $primary;
      }
    }

    .main-title-equifax {
      text-align: center;
      text-transform: uppercase;
      font-size: 2rem;
      @media (min-width: 1024px) {
        font-size: 2.5rem;
      }
      padding: 1rem 0;
      color: $gray-700;
      font-weight: 300;

      strong {
        font-weight: 700;
        color: #9f1b32;
      }
    }


    .items {
      margin: 15px 0;
      @media (min-width: 1024px) {
        margin: 30px 0;
      }
      padding: 0 45px;
      @media (max-width: 768px) {
        background-color: $gray-200;
        border-radius: 1rem;
        padding-top: 30px;
        padding-bottom: 15px;
      }
      @media (min-width: 1024px) {
        margin-right: 0;
        padding-right: 0;
      }
      @media (min-width: 1200px) {
        width: 360px;
        height: 290px;
        padding: 15px 30px 0 30px;
        background: url(../../assets/images/bg-new-solution-left-list.png) no-repeat center center;
        background-size: cover;
        margin-top: 45px;
      }

      &.bg-right {
        @media (min-width: 1024px) {
          margin-top: 30px;
        }
        @media (min-width: 1200px) {
          margin-top: 45px;
          padding-left: 90px;
          background-image: url(../../assets/images/bg-new-solution-right-list.png);
        }

        ul {
          padding-right: 0;

          li {
            padding-right: 0px;
          }
        }
      }

      ul {
        margin: 0 0 15px 0;
        padding: 0;
        @media (min-width: 1200px) {
          margin-bottom: 10px;
          padding: 0 30px 0 15px;
        }

        li {
          padding: 0;
          font-weight: 500;
          margin-bottom: 5px;
        }
      }

      .btn {
        @media (min-width: 1200px) {
          width: 245px;
        }
      }
    }

    .spacer {
      padding: 30px 0;
      @media (min-width: 1200px) {
        padding: 75px 0;
      }
    }

    .video-container {
      display: none;
      @media (min-width: 1200px) {
        display: block;
        position: relative;
        padding-top: 300px;
        width: 620px;
        height: 435px;
        background: url(../../assets/images/bg-homepage-video.png) no-repeat center center;
        background-size: cover;
      }

      .video-player {
        @media (min-width: 1200px) {
          position: absolute;
          top: 14px;
          left: 19px;
          width: 580px;
          height: 348px;
        }
      }
    }

    .discover {
      text-align: center;

      h3 {
        font-weight: 300;
        font-size: 1.5rem;

        strong {
          font-weight: 500;
        }
      }

      .btn {
        font-size: 0.9rem;
        padding: 0.375rem 0.5rem;
      }
    }
  }

  &.new-solution-equifax {
    @media (min-width: 1200px) {

    }

    .main-title {
      text-align: center;
      font-size: 2rem;
      @media (min-width: 1024px) {
        font-size: 2.5rem;
      }
      padding: 1rem 0;
      color: $gray-700;
      font-weight: 300;

      strong {
        font-weight: 700;
        color: $primary;
      }
    }

    .main-title-equifax {
      text-align: center;
      font-size: 2rem;
      @media (min-width: 1024px) {
        font-size: 2.5rem;
      }
      padding: 1rem 0;
      color: $gray-700;
      font-weight: 300;

      strong {
        font-weight: 700;
        color: #9f1b32;
      }
    }

    .items {
      margin: 15px 0;
      @media (min-width: 1024px) {
        margin: 30px 0;
      }
      padding: 0 45px;
      @media (max-width: 768px) {
        background-color: $gray-200;
        border-radius: 1rem;
        padding-top: 30px;
        padding-bottom: 15px;
      }
      @media (min-width: 1024px) {
        margin-right: 0;
        padding-right: 0;
      }
      @media (min-width: 1200px) {
        width: 360px;
        height: 260px;
        padding: 15px 30px 0 30px;
        background: url(../../assets/images/bg-new-solution-left-list.png) no-repeat center center;
        background-size: cover;
        margin-top: 45px;
      }

      &.bg-right {
        @media (min-width: 1024px) {
          margin-top: 30px;
        }
        @media (min-width: 1200px) {
          margin-top: 45px;
          padding-left: 90px;
          background-image: url(../../assets/images/bg-new-solution-right-list.png);
        }

        ul {
          padding-right: 0;

          li {
            padding-right: 0px;
          }
        }
      }

      ul {
        margin: 0 0 15px 0;
        padding: 0;
        @media (min-width: 1200px) {
          margin-bottom: 10px;
          padding: 0 30px 0 15px;
        }

        li {
          padding: 0;
          font-weight: 500;
          margin-bottom: 5px;
        }
      }

      .btn {
        @media (min-width: 1200px) {
          width: 245px;
        }
      }
    }

    .spacer {
      padding: 30px 0;
      @media (min-width: 1200px) {
        padding: 75px 0;
      }
    }

    .video-container {
      @media (min-width: 1200px) {
        position: relative;
        padding-top: 300px;
        width: 620px;
        height: 435px;
        background: url(../../assets/images/bg-homepage-video.png) no-repeat center center;
        background-size: cover;
      }

      .video-player {
        @media (min-width: 1200px) {
          position: absolute;
          top: 14px;
          left: 19px;
          width: 580px;
          height: 348px;
        }
      }
    }

    .discover {
      text-align: center;

      h3 {
        font-weight: 300;
        font-size: 1.5rem;

        strong {
          font-weight: 500;
        }
      }

      .btn {
        font-size: 0.9rem;
        padding: 0.375rem 0.5rem;
      }
    }
  }

  &.logos {
    text-align: center;

    img {
      margin: 0 auto;
      border: 1px solid #000;
      margin: 10px 5px;
      max-width: 200px;
    }
  }
}

.content-pad {
  padding-top: 120px;
  @media (max-width: 769px) {
    padding-top: 60px;
  }
}

.img-box {
  width: 100%;
  margin: 0 0 15px 0 !important;
}

.dark {
  color: #ffffff;
  background-color: #5a6770;
}
.box {
  //margin: 10px;
  position: relative;
  font-size: 1.4rem;
  line-height: 1.4rem;

  width: 100%;
  padding: 10px 27px;
  ::after{
    content: "";
    position: absolute;
    left: 10px;
    top: 68%;
    transform: translateY(-68%);
    height: 40px;
    width: 4px;
    background: #9c3328 !important;
  }

  //}
  //@media (max-width: 980px) {
  //  display: flex;
  //  padding: 5px;
  //  align-items: center;
  //}
}


.light {
  color: #ffffff;
  background-color: #a2aaad;
}

.estrella {
  position: absolute;
  left: -30px;
  top: -30px;
  @media (max-width: 980px) {
    display: none;
  }
}

.main-centrado {
  justify-content: center;

}

.mensaje-destacado {
  background-color: #9f1b32;
  color: #ffffff;
  font-size: 1.6rem;
  text-align: center;
  border-radius: 20px;
  padding: 20px;
}

.content-bullets-home {
  align-items: center;
  justify-content: center;
  display: flex;
  .col-1 {
    display: block;
  }
  @media (max-width: 1200px) {
    display: block;
    .col-1 {
      display: none;
    }
    .col-4 {
      flex: none !important;
      width: 100% !important;
      max-width: none !important;
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}

.img-content-bullets-home {
  @media (max-width: 980px) {
    display: none;
  }
}

.title-bullets-home {
  font-size: 3rem;
  color: #9f1b32;
}

//.text-bullets-home {
//  font-size: 1.6rem;
//  line-height: 1.8rem;
//  color: #5b6771;
//  text-transform: uppercase;
//  margin: 26px;
//
//  strong {
//    color: #9f1b32;
//  }
//
//  img {
//    position: absolute;
//    left: -10px;
//  }
//}

.solution-adoc {
  background-color: #9f1b32;
  color: #ffffff;
  padding-top: 30px;
}

.content-adoc{
  align-items: center;
  justify-content: center;
  display: flex;
}

.titular-adoc {
  text-transform: uppercase;
  font-size: 1.8rem;
  line-height: 2rem;
  margin-bottom: 20px;
}

.titular-adoc-min {
  font-size: 1.8rem;
  line-height: 2rem;
  margin-bottom: 20px;
}

.img-adoc {
  @media (max-width: 980px) {
    display: none;
  }
}

.footer-equifax {
  @media (max-width: 770px) {
    .justify-content-end {
      justify-content: center !important;
    }
  }
}

.content-box {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(6, 1fr);
  }
  grid-gap: 15px;

}

//.img-box {
//  margin: 20px 5px 5px 5px;
//  height: 130px;
//}

.estrella {
  position: absolute;
  left: -30px;
  top: -30px;
  @media (max-width: 980px) {
    display: none;
  }
}

.main-centrado {
  justify-content: center;

}

.mensaje-destacado {
  background-color: #9f1b32;
  color: #ffffff;
  font-size: 1.6rem;
  text-align: center;
  border-radius: 20px;
  padding: 20px;
}

.content-bullets-home {
  align-items: center;
  justify-content: center;
  display: flex;
}

.img-content-bullets-home {
  @media (max-width: 980px) {
    display: none;
  }
}

.title-bullets-home {
  font-size: 3rem;
  color: #9f1b32;
}

//.text-bullets-home {
//  font-size: 1.6rem;
//  line-height: 1.8rem;
//  color: #5b6771;
//  text-transform: uppercase;
//  margin: 26px;
//
//  strong {
//    color: #9f1b32;
//  }
//
//  img {
//    position: absolute;
//    left: -10px;
//  }
//}

.solution-adoc {
  background-color: #9f1b32;
  color: #ffffff;
  padding-top: 30px;
}

.content-adoc{
  align-items: center;
  justify-content: center;
  display: flex;
}

.titular-adoc {
  text-transform: uppercase;
  font-size: 1.8rem;
  line-height: 2rem;
  margin-bottom: 20px;
}

.img-adoc {
  @media (max-width: 980px) {
    display: none;
  }
}

.dark-box {
  color: #ffffff;
  background-color: #5a6770;
  margin: 10px;
  font-size: 1.2rem;
  line-height: 1.4rem;
  @media (min-width: 1024px) {
    min-height: 320px;
  }
  @media (max-width: 980px) {
    display: flex;
    padding: 5px;
    align-items: center;
  }
}

.light-box {
  color: #ffffff;
  background-color: #a2aaad;
  margin: 10px;
  font-size: 1.2rem;
  line-height: 1.4rem;
  @media (min-width: 1024px) {
    min-height: 320px;
  }
  @media (max-width: 980px) {
    display: flex;
    padding: 5px;
    align-items: center;
  }
}

.estrella {
  position: absolute;
  left: -30px;
  top: -30px;
  @media (max-width: 980px) {
    display: none;
  }
}

.main-centrado {
  justify-content: center;

}

.mensaje-destacado {
  background-color: #9f1b32;
  color: #ffffff;
  font-size: 1.6rem;
  text-align: center;
  border-radius: 20px;
  padding: 20px;
}

.content-bullets-home {
  align-items: center;
  justify-content: center;
  display: flex;
}

.img-content-bullets-home {
  @media (max-width: 980px) {
    display: none;
  }
}

.title-bullets-home {
  font-size: 3rem;
  color: #9f1b32;
}

.orange {
  color: #e77204 !important;
}
.blue {
  color: #007298 !important;
}
.purple {
  color: #652f6c !important;
}

.text-bullets-home {
  font-size: 1.6rem;
  line-height: 1.8rem;
  margin: 26px 0 26px 40px;

  strong {
    color: #9f1b32;
  }

  img {
    position: absolute;
    left: -10px;
  }
}

.solution-adoc {
  background-color: #9f1b32;
  color: #ffffff;
  padding-top: 30px;
}

.content-adoc{
  align-items: center;
  justify-content: center;
  display: flex;
}

.titular-adoc {
  text-transform: uppercase;
  font-size: 1.8rem;
  line-height: 2rem;
  margin-bottom: 20px;
}

.img-adoc {
  @media (max-width: 980px) {
    display: none;
  }
}

.font-body {
  font-size: 1rem;
}

.linea-video-equifax {
  width: 95%;
  margin: 2rem auto 0 auto;
  height: 0.25rem;
  background-color: #9f1b32;
  @media (max-width: 1200px) {
    width: 100%;
  }
}
.home-img {
  width: 100% !important;
  height: 100% !important;
  min-height: 473px !important;
  background: url(../../assets/images/splash-home-equifax.jpg) no-repeat center center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  @media (max-width: 650px) {
    align-items: center;
  }
  p, h1, a {
    margin-left: 1.5rem;
  }
  p {
    color: #9f1b32;
    font-size: 3.4rem;
    font-weight: bold;
    @media (max-width: 650px) {
      font-size: 2rem;
      margin: 16px 5px ;
    }
  }
  h1 {
    text-align: left;
    font-weight: normal;
    font-size: 2rem;
    margin-bottom: 32px;
    color: #000;
    text-transform: uppercase;
    @media (max-width: 650px) {
      font-size: 1.4rem;
      margin: 16px 5px ;
    }
  }
  button {
    @media (max-width: 650px) {
      font-size: 1.4rem;
      margin: 16px 5px ;
    }
  }

}