// RDPRO

#clientes_form_rdPro {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 56px;
  row-gap: 0;
}


///////////////

.line {
  width: 100%;
  height: 1px;
  border: solid 1px #dcdcdc;
}
.efx_color{
  color: #9f1b32 !important;
}
.efx_bg{
  background: #9f1b32 !important;
}
.paso {
  width: fit-content;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.4px;
  color: #000000;
  background: #fed803;
  border-radius: 15px;
  padding: 1.5px 7px;
}
.efx_paso {
  color: #ffffff !important;
  background: #9f1b32 !important;
  font-weight: 400;
}

#current_step {;
  position: relative;
  margin: 0 35px 0 20px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  &::before{
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 55px;
    height: 55px;
    background: #fed803;
    border-radius: 50%;
    z-index: -1;
  }
  &.equifax_color{
    color: #ffffff;
    &::before{
      background: #9f1b32;
    }
  }
}

#progressbar {
  width: 250px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  &_rdPro {
    width: 280px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
}

#image_tipo_expediente {
  width: 80px;
  height: 80px;
}

#progressbar li {
  list-style-type: none;
  font-size: 12px;
  width: 25%;
  position: relative;
  transition: all 0.5s ease;
  &:after {
    content: '';
    height: 3px;
    background: #dcdcdc 50%;
    position: absolute;
    left: -100%;
    top: 50%;
    z-index: -1;
    transition: all 0.5s ease;
  }
  &:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #dcdcdc;
    margin: 0;
    background: #ffffff;
    border: 3px solid #dcdcdc;
    border-radius: 50%;
    padding: 2px;
    font-weight: 400;
    transition: all 0.5s ease;
    .active {
      color: #fed803;
      border: 3px solid #fed803;
      transition: all 0.5s ease;
    }
  }
  &.active:before {
    color: #fed803;
    border: 3px solid #fed803;
  }
  &.completed:before {
    color: #000000;
    border: none;
    background: #fed803;
  }
}

#progressbar_rdPro li {
  list-style-type: none;
  font-size: 12px;
  width: 25%;
  position: relative;
  transition: all 0.5s ease;
  &:after {
    content: '';
    height: 3px;
    background: #dcdcdc 50%;
    position: absolute;
    left: -70%;
    top: 50%;
    z-index: -1;
    transition: all 0.5s ease;
  }
  &:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #dcdcdc;
    margin: 0;
    background: #ffffff;
    border: 3px solid #dcdcdc;
    border-radius: 50%;
    padding: 2px;
    font-weight: 400;
    transition: all 0.5s ease;
    .active {
      color: #fed803;
      border: 3px solid #fed803;
      transition: all 0.5s ease;
    }
  }
  &.active:before {
    color: #fed803;
    border: 3px solid #fed803;
  }
  &.completed:before {
    color: #000000;
    border: none;
    background: #fed803;
  }
}

#progressbar.equifax_color li {
  &:before {
    .active {
      color: #9f1b32 !important;
      border: 3px solid #9f1b32 !important;
    }
  }
  &.active:before {
    color: #9f1b32 !important;
    border: 3px solid #9f1b32 !important;
  }
  &.completed:before {
    color: #ffffff;
    background: #9f1b32 !important;
  }
}

#progressbar #step1:before {
  content: "1";
  margin: 0 12.5px;
}

#progressbar #step2:after {
  width: 300%;
}

#progressbar #step2:before {
  content: "2";
  margin: 0 12.5px;
}

#progressbar #step3:before {
  content: "3";
  margin-right: 0;
  margin-left: 12.5px;
}

#progressbar_rdPro #step1:before {
  content: "1";
  margin: 0 12.5px;
}

#progressbar_rdPro #step2:after {
  width: 300%;
}

#progressbar_rdPro #step2:before {
  content: "2";
  margin: 0 12.5px;
}

#progressbar_rdPro #step3:before {
  content: "3";
  margin: 0 12.5px !important;
}

#progressbar_rdPro #step4:before {
  content: "4";
  margin: 0 0 0 12.5px !important;
}

.card_form_expedientes{
  max-width: 534px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  background: #f2f2f2;
  font-size: 24px;
  transition: all 0.5s ease;
  p {
    transition: all 0.5s ease;
    margin: 1rem 0 1.5rem 0;
  }
  button {
    transition: all 0.5s ease;
  }
  img {
    transition: all 0.5s ease;
  }
}

.card_form_expedientes.active {
  background: #fed803;
  button {
    color: #ffffff !important;
    background-color: #000000 !important;
  }
  &.equifax_color {
    background: #9f1b32 !important;
    p {
      color: #ffffff !important;
    }
    img {
      filter: brightness(0) invert(1);
    }
    button {
      color: #000000 !important;
      background-color: #ffffff !important;
    }
  }
}

#transitions {
  min-height: 300px;
}

#form_step1, #step2, #step1, #step3, #step4 {
  transition: all 0.5s ease;
}

.extrajudicialPro {
  padding: 0;
  #form_extrajudicial_pro {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

#expedientes_pro_extrajudicial_0{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 56px;
  row-gap: 0;
  div:last-of-type {
    grid-column: 1 / span 2;
    grid-row: 2;
  }
}

.card_style {
  padding: 30px 31px 29px 29px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
}


#añadir_factura, #añadir_documento, #añadir_email, #añadir_telefono {
  a{
    cursor: pointer;
    transition: all 0.75s ease;
    :hover{
      -webkit-transform: scale(1.05,1.05);
      -moz-transform: scale(1.05,1.05);
      -ms-transform: scale(1.05,1.05);
      -o-transform: scale(1.05,1.05);
      transform: scale(1.05,1.05);
    }
  }
  p {
    color: #fed803;
    font-weight: 500;
    letter-spacing: 1.4px;
  }
}


#back_form1, #back_form2, #back_form1jud, #back_form_clientePro {
  max-width: 140px !important;
}

#expedientes_pro_facturas_0 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 56px;
  row-gap: 0;
  div:last-of-type {
    grid-column: 1 / span 2;
  }
}

#expedientes_pro_documentos_0 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 56px;
  row-gap: 0;
  div:first-of-type {
    grid-column: 1 / span 2;
  }
  div:last-of-type {
    grid-column: 2;
    grid-row: 2;
  }
}

.forms_pro:first-of-type {
  margin-top: 0;
}

.tipocliente {
  display: flex;
  align-items: center;
  input{
    width: 19px;
    height: 19px;
    margin: 0 6px 0 0;
  }
  :nth-child(2) {
    margin-right: 2.8rem !important;
  }
  :nth-child(5) {
    margin-left: 2.8rem !important;
  }
  label {
    margin: 0 0 0 6px;
    font-size: 16px;

  }
}

#form_step3 {

  #expedientes_pro_radiowho{
    display: flex;
    align-items: center;
    input{
      width: 19px;
      height: 19px;
      margin: 0 6px 0 0;
    }
    #expedientes_pro_radiowho_1, #expedientes_pro_radiowho_2 {
      margin-left: 2.8rem;
    }
    label {
      margin: 0 0 0 6px;
      font-size: 16px;
    }
  }

  #expedientes_pro_deudores_0, #expedientes_pro_deudoresjuridico_0, #expedientes_pro_deudores_1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 56px;
    row-gap: 0;
  }

  .observaciones {
    textarea {
      min-height: 100px;
    }
  }
}

#expedientes_pro_deudoresjuridico_0 {
  div:nth-of-type(3) {
    grid-column: 1 / span 2;
    grid-row: 2;
  }
}

.tipodocpro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px !important;
  input{
    width: 19px;
    height: 19px;
    margin: 0;
  }
  label {
    margin: 0 0 0 -20px;
    font-size: 16px;
    :first-of-type {
      margin: 0;
    }
  }
}

// SOLO PARA LA ANIMACIÓN DEL LOADING DEL FORM


.loader_info {
  margin: 2.9rem 0 0 10px;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
}

.loader {
  animation: rotate 2s linear infinite normal;
  height: 3.8vmin;
  position: relative;
  width: 3.8vmin;

  &__ball {
    animation: move1 1s ease-in-out infinite alternate;
    background: linear-gradient(-50deg, #fec000 0%, #fed803 100%);
    border-radius: 50%;
    height: 100%;
    position: absolute;
    width: 100%;

    &:nth-of-type(2) {
      animation-name: move2;
    }

    &:nth-of-type(3) {
      animation-name: move3;
    }
  }
}

.loader_equifax {
  animation: rotate 2s linear infinite normal;
  height: 3.8vmin;
  position: relative;
  width: 3.8vmin;

  &__ball {
    animation: move1 1s ease-in-out infinite alternate;
    background: linear-gradient(-50deg, #9f1b32 0%, #fe8971 100%);
    border-radius: 50%;
    height: 100%;
    position: absolute;
    width: 100%;

    &:nth-of-type(2) {
      animation-name: move2;
    }

    &:nth-of-type(3) {
      animation-name: move3;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes move1 {
  0%, 5% {
    transform: translate(0, 0);
  }
  to {
    transform: translate(0, -100%);
  }
}

@keyframes move2 {
  0%, 5% {
    transform: translate(0, 0);
  }
  to {
    transform: translate(100%, 100%);
  }
}

@keyframes move3 {
  0%, 15% {
    transform: translate(0);
  }
  to {
    transform: translate(-100%, 100%);
  }
}

//****************************************************************************************************************************//

.add_info_deudores {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 56px;
}

