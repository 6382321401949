.access-form {
  @media (min-width: 768px) {
    background: url(../../assets/images/bg-page-recupera-ya.jpg) no-repeat center center;
    background-size: cover;
  }

  .container {
    @media (min-width: 1024px) {
      min-height: 600px;
    }
    @media (min-width: 1200px) {
      min-height: 100vh;
    }
  }

  form {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 50px;
    margin-top: 6em;
    label {
      color: #fff;
      margin: 0;
    }
    .form-control {
      margin: 10px 0;
      background-color: #fff;
    }
    input[type="email"], input[type="password"] {
      border-color: #fff;
    }
    .btn {
      margin-bottom: 5px
    }
  }
}

.signin {
  &.splash {
    @media (min-width: 1024px) {
      background: url(../../assets/images/bg-signin.jpg) no-repeat center bottom;
      background-size: cover;
      min-height: 300px;
    }
    @media (min-width: 1200px) {
      min-height: 400px;
    }
    text-align: center;
  }
}

#customers {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 0.9rem;
  @media (max-width: 769px) {
    font-size: 0.66rem;
  }
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
  @media (max-width: 560px) {
    padding: 4px;
  }
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #FDF09A;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #FDF09A;
  color: black;
}


#customers-equifax {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 0.9rem;
  @media (max-width: 769px) {
    font-size: 0.66rem;
  }
}

#customers-equifax td, #customers-equifax th {
  border: 1px solid #ddd;
  padding: 8px;
  @media (max-width: 560px) {
    padding: 4px;
  }
}

#customers-equifax tr:nth-child(even){background-color: #f2f2f2;}

#customers-equifax tr:hover {background-color: #e09191;}

#customers-equifax th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #e09191;
  color: black;
}


#customers-equifax {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 0.9rem;
  @media (max-width: 769px) {
    font-size: 0.66rem;
  }
}

#customers-equifax td, #customers-equifax th {
  border: 1px solid #ddd;
  padding: 8px;
  @media (max-width: 560px) {
    padding: 4px;
  }
}

#customers-equifax tr:nth-child(even){background-color: #f2f2f2;}

#customers-equifax tr:hover {background-color: #e09191;}

#customers-equifax th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #e09191;
  color: black;
}


#customers-equifax {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 0.9rem;
  @media (max-width: 769px) {
    font-size: 0.66rem;
  }
}

#customers-equifax td, #customers-equifax th {
  border: 1px solid #ddd;
  padding: 8px;
  @media (max-width: 560px) {
    padding: 4px;
  }
}

#customers-equifax tr:nth-child(even){background-color: #f2f2f2;}

#customers-equifax tr:hover {background-color: #e09191;}

#customers-equifax th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #e09191;
  color: black;
}

.table-facturas {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #FDF09A;
  color: black;
  font-weight: bold;
  text-align: center;
}

.table-facturas-equifax {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #e09191;
  color: black;
  font-weight: bold;
  text-align: center;
}
.fecha-editar-documentos {
  min-width: 6rem;
}