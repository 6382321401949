.services {

  &.splash {
    @media (min-width: 1024px) {
      height: 300px;
      background: url(../../assets/images/bg-services.jpg) no-repeat center top;
      background-size: cover;
    }
    @media (min-width: 1200px) {
      min-height: 400px;
    }
  }

  &.sue {
    .row {
      background-color: $gray-100;
    }

    .demanda-monitorio {
      display: block;
      width: 100%;
      height: 460px;
      background-image: url(../../assets/images/demanda-monitorio-mobile.png);
      @media (min-width: 768px){
        background-image: url(../../assets/images/demanda-monitorio-tablet.png);
        height: 600px;
      }
      @media (min-width: 1024px) {
        height: 540px;
        background-image: url(../../assets/images/demanda-monitorio.png);
      }
      background-size: cover;
      background-repeat: no-repeat;
    }

    .text-bottom {
      @media (max-width: 768px) {
        flex-wrap: wrap;
        margin: 1rem 0;
      }
      .note {
        font-size: 0.8rem;
      }
    }
  }

  .price {
    background-color: $gray-300;
    padding: 0.5rem 1rem;
    align-content: center;
    min-width: 230px;
  }

  &.extra {
    .text {
      padding: 0 100px;
      text-align: center;
      @media (max-width: 768px) {
        padding: 0;
      }
    }
  }

  &.specs {
    .card {
      border: 2px solid $gray-200;
      border-radius: 0;
      margin-bottom: 1rem;
    }
    p {
      text-align: left;
      margin: 0;
    }
    .icon {
      background-color: $gray-200;
      img {
        width: 100px;
        height: 120px;
      }
    }
    .spec-title {
      font-size: 1.2rem;
    }
    .price {
      margin: 1rem 0 0;
    }
  }

  &.rates {
    .row {
      @media (min-width: 1024px) {
        padding: 0 100px;
      }
    }

    .rate-title {
      background-color: lighten($primary, 30%) !important;
      margin-bottom: 10px;
    }

    div[class*="col"] {
      background-color: lighten($primary, 40%);
      @media (min-width: 1024px) {
        margin-bottom: 10px;
      }
    }
    .col-md-4 {
      @media (max-width: 1024px) {
        margin-bottom: 10px;
      }
    }

    p {
      margin: 10px 20px;
    }
  }
}


.services-equifax {

  &.splash {
    @media (min-width: 1024px) {
      height: 300px;
      background: url(../../assets/images/bg-services-equifax.jpg) no-repeat center top;
      background-size: cover;
    }
    @media (min-width: 1200px) {
      min-height: 400px;
    }
  }

  &.sue {
    .row {
      background-color: $gray-100;
    }

    .demanda-monitorio {
      display: block;
      width: 100%;
      height: 460px;
      background-image: url(../../assets/images/demanda-monitorio-mobile-equifax.png);
      @media (min-width: 768px){
        background-image: url(../../assets/images/demanda-monitorio-tablet-equifax.png);
        height: 600px;
      }
      @media (min-width: 1024px) {
        height: 540px;
        background-image: url(../../assets/images/demanda-monitorio-equifax.png);
      }
      background-size: cover;
      background-repeat: no-repeat;
    }

    .text-bottom {
      @media (max-width: 768px) {
        flex-wrap: wrap;
        margin: 1rem 0;
      }
      .note {
        font-size: 0.8rem;
      }
    }
  }

  .price {
    background-color: $gray-300;
    padding: 0.5rem 1rem;
    align-content: center;
    min-width: 230px;
  }

  &.extra {
    .text {
      padding: 0 100px;
      text-align: center;
      @media (max-width: 768px) {
        padding: 0;
      }
    }
  }

  &.specs {
    .card {
      border: 2px solid $gray-200;
      border-radius: 0;
      margin-bottom: 1rem;
    }
    p {
      text-align: left;
      margin: 0;
    }
    .icon {
      background-color: $gray-200;
      img {
        width: 100px;
        height: 120px;
      }
    }
    .spec-title {
      font-size: 1.2rem;
    }
    .price {
      margin: 1rem 0 0;
    }
  }

  &.rates {
    .row {
      @media (min-width: 1024px) {
        padding: 0 100px;
      }
    }

    .rate-title {
      background-color: lighten(#9f1b32, 30%) !important;
      margin-bottom: 10px;
    }

    div[class*="col"] {
      background-color: lighten(#9f1b32, 60%);
      @media (min-width: 1024px) {
        margin-bottom: 10px;
      }
    }
    .col-md-4 {
      @media (max-width: 1024px) {
        margin-bottom: 10px;
      }
    }

    p {
      margin: 10px 20px;
    }
  }
}


.services-equifax {

  &.splash {
    @media (min-width: 1024px) {
      height: 300px;
      background: url(../../assets/images/bg-services-equifax.jpg) no-repeat center top;
      background-size: cover;
    }
    @media (min-width: 1200px) {
      min-height: 400px;
    }
  }

  &.sue {
    .row {
      background-color: $gray-100;
    }

    .demanda-monitorio {
      display: block;
      width: 100%;
      height: 460px;
      background-image: url(../../assets/images/demanda-monitorio-mobile-equifax.png);
      @media (min-width: 768px){
        background-image: url(../../assets/images/demanda-monitorio-tablet-equifax.png);
        height: 600px;
      }
      @media (min-width: 1024px) {
        height: 540px;
        background-image: url(../../assets/images/demanda-monitorio-equifax.png);
      }
      background-size: cover;
      background-repeat: no-repeat;
    }

    .text-bottom {
      @media (max-width: 768px) {
        flex-wrap: wrap;
        margin: 1rem 0;
      }
      .note {
        font-size: 0.8rem;
      }
    }
  }

  .price {
    background-color: $gray-300;
    padding: 0.5rem 1rem;
    align-content: center;
    min-width: 230px;
  }

  &.extra {
    .text {
      padding: 0 100px;
      text-align: center;
      @media (max-width: 768px) {
        padding: 0;
      }
    }
  }

  &.specs {
    .card {
      border: 2px solid $gray-200;
      border-radius: 0;
      margin-bottom: 1rem;
    }
    p {
      text-align: left;
      margin: 0;
    }
    .icon {
      background-color: $gray-200;
      img {
        width: 100px;
        height: 120px;
      }
    }
    .spec-title {
      font-size: 1.2rem;
    }
    .price {
      margin: 1rem 0 0;
    }
  }

  &.rates {
    .row {
      @media (min-width: 1024px) {
        padding: 0 100px;
      }
    }

    .rate-title {
      background-color: lighten(#9f1b32, 30%) !important;
      margin-bottom: 10px;
    }

    div[class*="col"] {
      background-color: lighten(#9f1b32, 60%);
      @media (min-width: 1024px) {
        margin-bottom: 10px;
      }
    }
    .col-md-4 {
      @media (max-width: 1024px) {
        margin-bottom: 10px;
      }
    }

    p {
      margin: 10px 20px;
    }
  }
}


.services-equifax {

  &.splash {
    @media (min-width: 1024px) {
      height: 300px;
      background: url(../../assets/images/bg-services-equifax.jpg) no-repeat center top;
      background-size: cover;
    }
    @media (min-width: 1200px) {
      min-height: 400px;
    }
  }

  &.sue {
    .row {
      background-color: $gray-100;
    }

    .demanda-monitorio {
      display: block;
      width: 100%;
      height: 460px;
      background-image: url(../../assets/images/demanda-monitorio-mobile-equifax.png);
      @media (min-width: 768px){
        background-image: url(../../assets/images/demanda-monitorio-tablet-equifax.png);
        height: 600px;
      }
      @media (min-width: 1024px) {
        height: 540px;
        background-image: url(../../assets/images/demanda-monitorio-equifax.png);
      }
      background-size: cover;
      background-repeat: no-repeat;
    }

    .text-bottom {
      @media (max-width: 768px) {
        flex-wrap: wrap;
        margin: 1rem 0;
      }
      .note {
        font-size: 0.8rem;
      }
    }
  }

  .price {
    background-color: $gray-300;
    padding: 0.5rem 1rem;
    align-content: center;
    min-width: 230px;
  }

  &.extra {
    .text {
      padding: 0 100px;
      text-align: center;
      @media (max-width: 768px) {
        padding: 0;
      }
    }
  }

  &.specs {
    .card {
      border: 2px solid $gray-200;
      border-radius: 0;
      margin-bottom: 1rem;
    }
    p {
      text-align: left;
      margin: 0;
    }
    .icon {
      background-color: $gray-200;
      img {
        width: 100px;
        height: 120px;
      }
    }
    .spec-title {
      font-size: 1.2rem;
    }
    .price {
      margin: 1rem 0 0;
    }
  }

  &.rates {
    .row {
      @media (min-width: 1024px) {
        padding: 0 100px;
      }
    }

    .rate-title {
      background-color: lighten(#9f1b32, 30%) !important;
      margin-bottom: 10px;
    }

    div[class*="col"] {
      background-color: lighten(#9f1b32, 60%);
      @media (min-width: 1024px) {
        margin-bottom: 10px;
      }
    }
    .col-md-4 {
      @media (max-width: 1024px) {
        margin-bottom: 10px;
      }
    }

    p {
      margin: 10px 20px;
    }
  }
}
