.check {
  &.splash {
    @media (min-width: 1024px) {
      background: url(../../assets/images/bg-check.jpg) no-repeat center bottom;
      background-size: cover;
      min-height: 300px;
    }
    @media (min-width: 1200px) {
      min-height: 400px;
    }
  }

  &.form {
    form {
      position: relative;
    }
    .form-inline, .inline {
      padding-top: 0.5rem;
      margin-bottom: 0.5rem;
      border-top: 10px solid lighten($primary, 30%);
    }

    .label {
      font-weight: bold;
      position: relative;
      padding-left: 30px;
      @media (max-width: 768px) {
        margin: .5rem 0;
        display: block;
        width: 100%;
      }
      @media (min-width: 768px) {
        padding-right: 2rem;
        display: inline-block;
        min-width: 320px;
      }
      &::before {
        content: "";
        position: absolute;
        top: -6px;
        left: 0;
        display: inline-block;
        width: 25px;
        height: 30px;
        background: url(../../assets/images/ico-arrow.png) no-repeat center 10px;
        background-size: 23px 16px;
      }
    }

    .inline {
      input[type="radio"] {
        margin-left: 0;
      }
      .form-check-label {
        margin-left: 1.25rem;
        padding-right: 0.5rem;
      }
      .form-control {
        display: inline-block;
      }
    }

    .who {
      .form-check-label {
        @media (min-width: 1024px) {
          min-width: 180px;
        }
        @media (min-width: 1200px) {
          min-width: 220px;
        }
      }
    }

    .claim-before {
      .form-check-input {
        margin-top: 0.7rem;
      }
    }

    .form-check-input {
      @media (min-width: 1024px) {
        margin-top: 0.15rem;
      }
    }

    input[type="date"], input[type="number"] {
      width: 180px;
    }

    input[type="email"] {
      @media (min-width: 1024px) {
        width: 500px;
      }
    }
  }

  .bottom {
    padding: 1rem;
    background-color: lighten($primary, 30%);
    .form-check {
      @media (max-width: 768px) {
        padding-top: 1rem;
      }
      @media (min-width: 1024px) {
        padding-top: 6px;
      }
    }
    p {
      margin: 0;
    }
  }

  .result {
    position: absolute;
    min-height: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
    .result-container {
      position: relative;
      min-height: 100%;
      height: 100%;
      display: inline-block;
    }
    .close {
      position: absolute;
      top: 15px;
      right: 15px;
      i {
        color: #fff;
      }
    }
    .section-title {
      color: $primary;
      padding-top: 6rem;
      @media (max-width: 768px) {
        padding-right: 3rem;
        padding-left: 3rem;
      }
    }
    p {
      font-size: 1.2rem;
      margin-bottom: 4rem;
      @media (max-width: 768px) {
        padding: 0 3rem;
      }
      @media (min-width: 1024px) {
        padding: 0 12rem;
      }

    }
  }
}

.check-equifax {
  &.splash {
    @media (min-width: 1024px) {
      background: url(../../assets/images/bg-check.jpg) no-repeat center bottom;
      background-size: cover;
      min-height: 300px;
    }
    @media (min-width: 1200px) {
      min-height: 400px;
    }
  }

  &.form {
    form {
      position: relative;
    }
    .form-inline, .inline {
      padding-top: 0.5rem;
      margin-bottom: 0.5rem;
      border-top: 10px solid lighten(#9f1b32, 50%);
    }

    .label {
      font-weight: bold;
      position: relative;
      padding-left: 30px;
      @media (max-width: 768px) {
        margin: .5rem 0;
        display: block;
        width: 100%;
      }
      @media (min-width: 768px) {
        padding-right: 2rem;
        display: inline-block;
        min-width: 320px;
      }
      &::before {
        content: "";
        position: absolute;
        top: -6px;
        left: 0;
        display: inline-block;
        width: 25px;
        height: 30px;
        background: url(../../assets/images/ico-arrow.png) no-repeat center 10px;
        background-size: 23px 16px;
      }
    }

    .inline {
      input[type="radio"] {
        margin-left: 0;
      }
      .form-check-label {
        margin-left: 1.25rem;
        padding-right: 0.5rem;
      }
      .form-control {
        display: inline-block;
      }
    }

    .who {
      .form-check-label {
        @media (min-width: 1024px) {
          min-width: 180px;
        }
        @media (min-width: 1200px) {
          min-width: 220px;
        }
      }
    }

    .claim-before {
      .form-check-input {
        margin-top: 0.7rem;
      }
    }

    .form-check-input {
      @media (min-width: 1024px) {
        margin-top: 0.15rem;
      }
    }

    input[type="date"], input[type="number"] {
      width: 180px;
    }

    input[type="email"] {
      @media (min-width: 1024px) {
        width: 500px;
      }
    }
  }

  .bottom {
    padding: 1rem;
    background-color: lighten(#9f1b32, 50%);
    .form-check {
      @media (max-width: 768px) {
        padding-top: 1rem;
      }
      @media (min-width: 1024px) {
        padding-top: 6px;
      }
    }
    p {
      margin: 0;
    }
  }

  .result {
    position: absolute;
    min-height: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
    .result-container {
      position: relative;
      min-height: 100%;
      height: 100%;
      display: inline-block;
    }
    .close {
      position: absolute;
      top: 15px;
      right: 15px;
      i {
        color: #fff;
      }
    }
    .section-title {
      color: $primary;
      padding-top: 6rem;
      @media (max-width: 768px) {
        padding-right: 3rem;
        padding-left: 3rem;
      }
    }
    p {
      font-size: 1.2rem;
      margin-bottom: 4rem;
      @media (max-width: 768px) {
        padding: 0 3rem;
      }
      @media (min-width: 1024px) {
        padding: 0 12rem;
      }

    }
  }
}

.check-equifax {
  &.splash {
    @media (min-width: 1024px) {
      background: url(../../assets/images/bg-check.jpg) no-repeat center bottom;
      background-size: cover;
      min-height: 300px;
    }
    @media (min-width: 1200px) {
      min-height: 400px;
    }
  }

  &.form {
    form {
      position: relative;
    }
    .form-inline, .inline {
      padding-top: 0.5rem;
      margin-bottom: 0.5rem;
      border-top: 10px solid lighten(#9f1b32, 50%);
    }

    .label {
      font-weight: bold;
      position: relative;
      padding-left: 30px;
      @media (max-width: 768px) {
        margin: .5rem 0;
        display: block;
        width: 100%;
      }
      @media (min-width: 768px) {
        padding-right: 2rem;
        display: inline-block;
        min-width: 320px;
      }
      &::before {
        content: "";
        position: absolute;
        top: -6px;
        left: 0;
        display: inline-block;
        width: 25px;
        height: 30px;
        background: url(../../assets/images/ico-arrow.png) no-repeat center 10px;
        background-size: 23px 16px;
      }
    }

    .inline {
      input[type="radio"] {
        margin-left: 0;
      }
      .form-check-label {
        margin-left: 1.25rem;
        padding-right: 0.5rem;
      }
      .form-control {
        display: inline-block;
      }
    }

    .who {
      .form-check-label {
        @media (min-width: 1024px) {
          min-width: 180px;
        }
        @media (min-width: 1200px) {
          min-width: 220px;
        }
      }
    }

    .claim-before {
      .form-check-input {
        margin-top: 0.7rem;
      }
    }

    .form-check-input {
      @media (min-width: 1024px) {
        margin-top: 0.15rem;
      }
    }

    input[type="date"], input[type="number"] {
      width: 180px;
    }

    input[type="email"] {
      @media (min-width: 1024px) {
        width: 500px;
      }
    }
  }

  .bottom {
    padding: 1rem;
    background-color: lighten(#9f1b32, 50%);
    .form-check {
      @media (max-width: 768px) {
        padding-top: 1rem;
      }
      @media (min-width: 1024px) {
        padding-top: 6px;
      }
    }
    p {
      margin: 0;
    }
  }

  .result {
    position: absolute;
    min-height: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
    .result-container {
      position: relative;
      min-height: 100%;
      height: 100%;
      display: inline-block;
    }
    .close {
      position: absolute;
      top: 15px;
      right: 15px;
      i {
        color: #fff;
      }
    }
    .section-title {
      color: $primary;
      padding-top: 6rem;
      @media (max-width: 768px) {
        padding-right: 3rem;
        padding-left: 3rem;
      }
    }
    p {
      font-size: 1.2rem;
      margin-bottom: 4rem;
      @media (max-width: 768px) {
        padding: 0 3rem;
      }
      @media (min-width: 1024px) {
        padding: 0 12rem;
      }

    }
  }
}

.check-equifax {
  &.splash {
    @media (min-width: 1024px) {
      background: url(../../assets/images/bg-check.jpg) no-repeat center bottom;
      background-size: cover;
      min-height: 300px;
    }
    @media (min-width: 1200px) {
      min-height: 400px;
    }
  }

  &.form {
    form {
      position: relative;
    }
    .form-inline, .inline {
      padding-top: 0.5rem;
      margin-bottom: 0.5rem;
      border-top: 10px solid lighten(#9f1b32, 50%);
    }

    .label {
      font-weight: bold;
      position: relative;
      padding-left: 30px;
      @media (max-width: 768px) {
        margin: .5rem 0;
        display: block;
        width: 100%;
      }
      @media (min-width: 768px) {
        padding-right: 2rem;
        display: inline-block;
        min-width: 320px;
      }
      &::before {
        content: "";
        position: absolute;
        top: -6px;
        left: 0;
        display: inline-block;
        width: 25px;
        height: 30px;
        background: url(../../assets/images/ico-arrow.png) no-repeat center 10px;
        background-size: 23px 16px;
      }
    }

    .inline {
      input[type="radio"] {
        margin-left: 0;
      }
      .form-check-label {
        margin-left: 1.25rem;
        padding-right: 0.5rem;
      }
      .form-control {
        display: inline-block;
      }
    }

    .who {
      .form-check-label {
        @media (min-width: 1024px) {
          min-width: 180px;
        }
        @media (min-width: 1200px) {
          min-width: 220px;
        }
      }
    }

    .claim-before {
      .form-check-input {
        margin-top: 0.7rem;
      }
    }

    .form-check-input {
      @media (min-width: 1024px) {
        margin-top: 0.15rem;
      }
    }

    input[type="date"], input[type="number"] {
      width: 180px;
    }

    input[type="email"] {
      @media (min-width: 1024px) {
        width: 500px;
      }
    }
  }

  .bottom {
    padding: 1rem;
    background-color: lighten(#9f1b32, 50%);
    .form-check {
      @media (max-width: 768px) {
        padding-top: 1rem;
      }
      @media (min-width: 1024px) {
        padding-top: 6px;
      }
    }
    p {
      margin: 0;
    }
  }

  .result {
    position: absolute;
    min-height: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
    .result-container {
      position: relative;
      min-height: 100%;
      height: 100%;
      display: inline-block;
    }
    .close {
      position: absolute;
      top: 15px;
      right: 15px;
      i {
        color: #fff;
      }
    }
    .section-title {
      color: $primary;
      padding-top: 6rem;
      @media (max-width: 768px) {
        padding-right: 3rem;
        padding-left: 3rem;
      }
    }
    p {
      font-size: 1.2rem;
      margin-bottom: 4rem;
      @media (max-width: 768px) {
        padding: 0 3rem;
      }
      @media (min-width: 1024px) {
        padding: 0 12rem;
      }

    }
  }
}