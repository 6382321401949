.faq {
  &.splash {
    @media (min-width: 1024px) {
      background: url(../../assets/images/bg-faq.jpg) no-repeat center center;
      background-size: cover;
      min-height: 300px;
    }
  }

  .accordion {
    .card {
      border: none;
      border-bottom: 1px solid #000 !important;
      border-radius: 0;
      position: relative;
      &:first-child {
        border-top: 1px solid #000;
      }
      &::before {
        content: "";
        position: absolute;
        left: 10px;
        top: 10px;
        display: inline-block;
        width: 25px;
        height: 30px;
        background: url(../../assets/images/ico-arrow.png) no-repeat center 10px;
        background-size: 23px 16px;
      }
      .card-header {
        border-radius: 0;
        border: none;
        background-color: #fff;
        padding: 0;
        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 100%;
          width: 100%;
          padding: 10px 15px 10px 60px;
          color: #000;
          text-decoration: none;
          font-weight: 500;
          background-color: lighten($primary, 30%);

          span {
            float: right;
            &:before {
              display: inline-block;
              font-style: normal;
              font-variant: normal;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              font-size: 1.5rem;
            }
          }
          &.collapsed {
            background-color: #fff;
            span:before {
              content: "\f067";
              color: $primary;
            }
          }
          span {
            &:before {
              content: "\f068";
            }
          }
        }
      }
      .card-body {
        background-color: $gray-200;
      }
    }
  }

}

.faq-equifax {
  &.splash {
    @media (min-width: 1024px) {
      background: url(../../assets/images/bg-faq.jpg) no-repeat center center;
      background-size: cover;
      min-height: 300px;
    }
  }

  .accordion {
    .card {
      border: none;
      border-bottom: 1px solid #000 !important;
      border-radius: 0;
      position: relative;
      &:first-child {
        border-top: 1px solid #000;
      }
      &::before {
        content: "";
        position: absolute;
        left: 10px;
        top: 10px;
        display: inline-block;
        width: 25px;
        height: 30px;
        background: url(../../assets/images/ico-arrow.png) no-repeat center 10px;
        background-size: 23px 16px;
      }
      .card-header {
        border-radius: 0;
        border: none;
        background-color: #fff;
        padding: 0;
        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 100%;
          width: 100%;
          padding: 10px 15px 10px 60px;
          color: #000;
          text-decoration: none;
          font-weight: 500;
          background-color: lighten(#9f1b32, 50%);

          span {
            float: right;
            &:before {
              display: inline-block;
              font-style: normal;
              font-variant: normal;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              font-size: 1.5rem;
            }
          }
          &.collapsed {
            background-color: #fff;
            span:before {
              content: "\f067";
              color: #9f1b32;
            }
          }
          span {
            &:before {
              content: "\f068";
            }
          }
        }
      }
      .card-body {
        background-color: $gray-200;
      }
    }
  }

}

.faq-equifax {
  &.splash {
    @media (min-width: 1024px) {
      background: url(../../assets/images/bg-faq.jpg) no-repeat center center;
      background-size: cover;
      min-height: 300px;
    }
  }

  .accordion {
    .card {
      border: none;
      border-bottom: 1px solid #000 !important;
      border-radius: 0;
      position: relative;
      &:first-child {
        border-top: 1px solid #000;
      }
      &::before {
        content: "";
        position: absolute;
        left: 10px;
        top: 10px;
        display: inline-block;
        width: 25px;
        height: 30px;
        background: url(../../assets/images/ico-arrow.png) no-repeat center 10px;
        background-size: 23px 16px;
      }
      .card-header {
        border-radius: 0;
        border: none;
        background-color: #fff;
        padding: 0;
        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 100%;
          width: 100%;
          padding: 10px 15px 10px 60px;
          color: #000;
          text-decoration: none;
          font-weight: 500;
          background-color: lighten(#9f1b32, 50%);

          span {
            float: right;
            &:before {
              display: inline-block;
              font-style: normal;
              font-variant: normal;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              font-size: 1.5rem;
            }
          }
          &.collapsed {
            background-color: #fff;
            span:before {
              content: "\f067";
              color: #9f1b32;
            }
          }
          span {
            &:before {
              content: "\f068";
            }
          }
        }
      }
      .card-body {
        background-color: $gray-200;
      }
    }
  }

}

.faq-equifax {
  &.splash {
    @media (min-width: 1024px) {
      background: url(../../assets/images/bg-faq.jpg) no-repeat center center;
      background-size: cover;
      min-height: 300px;
    }
  }

  .accordion {
    .card {
      border: none;
      border-bottom: 1px solid #000 !important;
      border-radius: 0;
      position: relative;
      &:first-child {
        border-top: 1px solid #000;
      }
      &::before {
        content: "";
        position: absolute;
        left: 10px;
        top: 10px;
        display: inline-block;
        width: 25px;
        height: 30px;
        background: url(../../assets/images/ico-arrow.png) no-repeat center 10px;
        background-size: 23px 16px;
      }
      .card-header {
        border-radius: 0;
        border: none;
        background-color: #fff;
        padding: 0;
        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 100%;
          width: 100%;
          padding: 10px 15px 10px 60px;
          color: #000;
          text-decoration: none;
          font-weight: 500;
          background-color: lighten(#9f1b32, 50%);

          span {
            float: right;
            &:before {
              display: inline-block;
              font-style: normal;
              font-variant: normal;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              font-size: 1.5rem;
            }
          }
          &.collapsed {
            background-color: #fff;
            span:before {
              content: "\f067";
              color: #9f1b32;
            }
          }
          span {
            &:before {
              content: "\f068";
            }
          }
        }
      }
      .card-body {
        background-color: $gray-200;
      }
    }
  }

}

.tabla-precios {
  .row {
    @media (min-width: 1024px) {
      padding: 0 100px;
    }
  }

  .rate-title {
    background-color: lighten(#9f1b32, 30%) !important;
    margin-bottom: 10px;
  }

  div[class*="col"] {
    background-color: lighten(#9f1b32, 90%);
    @media (min-width: 1024px) {
      margin-bottom: 2px;
    }
  }

  .col-md-4 {
    @media (max-width: 1024px) {
      margin-bottom: 2px;
    }
  }

  p {
    margin: 10px 20px;
  }
}
