.navbar .container {
  position: relative;
}

.topbar {
  display: none;
  @media (min-width: 1200px) {
    display: block;
    position: absolute;
    padding: 0.3rem 0.5rem 0.2rem 0.5rem;
    font-size: 0.875rem;
    top: -0.5rem;
    right: 15px;
    background-color: #000;
    color: #fff;
    a {
      color: #fff;
      padding: 0 0.15rem;
    }
  }
}
.topbar-equifax {
  display: none;
  @media (min-width: 1200px) {
    display: block;
    position: absolute;
    padding: 0.3rem 0rem 0.2rem 0.5rem;
    font-size: 0.9rem;
    top: -0.5rem;
    right: 15px;
    color: #fff;
    a {
      color: #fff;
      padding: 0 0.15rem;
    }
  }
}
.btn-navbar-equifax{
  background-color: transparent;
  border: 2px solid #ffffff;
  color: #ffffff;
  height: 30px;
  border-radius: 5px;
  font-weight: normal;
  transition-duration: 0.4s;
}
.btn-navbar-equifax:hover{
  background-color: #ffffff;
  color: #9f1b32;
}

.topbar-equifax {
  display: none;
  @media (min-width: 1200px) {
    display: block;
    position: absolute;
    padding: 0.3rem 0rem 0.2rem 0.5rem;
    font-size: 0.9rem;
    top: -0.5rem;
    right: 15px;
    color: #fff;
    a {
      color: #fff;
      padding: 0 0.15rem;
    }
  }
}

.btn-navbar-equifax{
  background-color: transparent;
  border: 2px solid #ffffff;
  color: #ffffff;
  height: 30px;
  border-radius: 5px;
  font-weight: normal;
  transition-duration: 0.4s;
}
.btn-navbar-equifax:hover{
  background-color: #ffffff;
  color: #9f1b32;
}

.navbar-brand {
  float: left;
  display: block;
  width: 150px;
  @media (min-width: 992px) {
    width: 200px;
  }
  @media (min-width: 1200px) {
    width: 275px;
    height: 90px;
  }
  img {
    max-width: 100%;
    height: auto;
    margin: auto;
  }
}