.new-solution {
  &.intro {
    @media (min-width: 1024px) {
      background: url(../../assets/images/bg-new-solution.png) no-repeat center bottom;
    }
  }

  .spec {
    border: 2px solid #000;
    text-align: center;
    margin-bottom: 1rem;
    @media (min-width: 1024px) {
      min-height: 540px;
    }
    .spec-header {
      background-color: #bababa;
      padding: 5px;
      min-height: 215px;
      img {
        width: 120px;
        height: auto;
        margin: 10px;
      }
      .spec-title {
        margin: 0;
        font-size: 1.2rem;
        padding: 0 15px;
        @media (min-width: 1024px) {
          padding: 0;
        }
      }
    }

    p {
      margin: 0;
      padding: 15px 20px;
      @media (min-width: 1024px) {
        padding: 15px 10px;
      }
    }
  }

  .spec-2 {
    border: 2px solid #000;
    text-align: center;
    margin-bottom: 1rem;
    @media (min-width: 1024px) {
      min-height: 450px;
    }
    .spec-header-2 {
      background-color: #bababa;
      padding: 5px;
      min-height: 60px;
      img {
        width: 120px;
        height: auto;
        margin: 10px;
      }
      .spec-title-2 {
        margin: 0;
        font-size: 1.2rem;
        padding: 0 15px;
        @media (min-width: 1024px) {
          padding: 0;
        }
      }
    }

    p {
      margin: 0;
      padding: 15px 20px;
      @media (min-width: 1024px) {
        padding: 15px 10px;
      }
    }
  }

  .spec-2 {
    border: 2px solid #000;
    text-align: center;
    margin-bottom: 1rem;
    @media (min-width: 1024px) {
      min-height: 450px;
    }
    .spec-header-2 {
      background-color: #bababa;
      padding: 5px;
      min-height: 60px;
      img {
        width: 120px;
        height: auto;
        margin: 10px;
      }
      .spec-title-2 {
        margin: 0;
        font-size: 1.2rem;
        padding: 0 15px;
        @media (min-width: 1024px) {
          padding: 0;
        }
      }
    }

    p {
      margin: 0;
      padding: 15px 20px;
      @media (min-width: 1024px) {
        padding: 15px 10px;
      }
    }
  }

  .spec-2 {
    border: 2px solid #000;
    text-align: center;
    margin-bottom: 1rem;
    @media (min-width: 1024px) {
      min-height: 450px;
    }
    .spec-header-2 {
      background-color: #bababa;
      padding: 5px;
      min-height: 60px;
      img {
        width: 120px;
        height: auto;
        margin: 10px;
      }
      .spec-title-2 {
        margin: 0;
        font-size: 1.2rem;
        padding: 0 15px;
        @media (min-width: 1024px) {
          padding: 0;
        }
      }
    }

    p {
      margin: 0;
      padding: 15px 20px;
      @media (min-width: 1024px) {
        padding: 15px 10px;
      }
    }
  }
}