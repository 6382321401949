$theme-colors: (
  "primary": #fed803,
  "primary-equifax": #9f1b32,
);

$font-family-base: 'Roboto', sans-serif !default;
$font-weight-base: 300;
$headings-font-family: 'Roboto', sans-serif !default;

@import "bootstrap/bootstrap";
@import "../node_modules/hamburgers/_sass/hamburgers/hamburgers";

@import "layout/header";
@import "layout/nav";
@import "layout/footer";
@import "layout/elements";

@import "pages/home";
@import "pages/who_we_are";
@import "pages/new-solution";
@import "pages/how-it-works";
@import "pages/services";
@import "pages/access-form";
@import "pages/faq";
@import "pages/contact";
@import "pages/check";
@import "pages/blog";
@import "pages/user-expedientes";
@import "pages/panel-control";
@import "pages/formulario-expediente";