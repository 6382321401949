.contact {
  &.splash {
    @media (min-width: 1024px) {
      background: url(../../assets/images/bg-contact.jpg) no-repeat center bottom;
      background-size: cover;
      min-height: 300px;
    }
    text-align: center;
  }

  .map {
    width: 100%;
    padding-bottom: 75%;
    margin-bottom: 1rem;
    background-color: $gray-200;
  }

  .contact-info {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      padding: 25px 0 25px 75px;
      background-position: top left;
      background-repeat: no-repeat;
      background-size: 70px 70px;
      min-height: 75px;
      &.timetable {
        background-image: url(../../assets/images/ico-contact-clock.png);
      }
      &.chat {
        background-image: url(../../assets/images/ico-contact-chat.png);
      }
      &.email {
        background-image: url(../../assets/images/ico-contact-email.png);
        a {
          color: #000;
        }
      }
      &.details {
        background-image: url(../../assets/images/ico-contact-map.png);
      }
    }
  }

  .recupera-ya {
    width: 300px;
    display: block;
    margin: 0 auto;
    @media (min-width: 1024px) {
      width: 140px;
      margin: 0;
    }
    @media (min-width: 1200px) {
      width: 165px;
    }
  }

}

.contact-equifax {
  &.splash {
    @media (min-width: 1024px) {
      background: url(../../assets/images/bg-contact.jpg) no-repeat center bottom;
      background-size: cover;
      min-height: 300px;
    }
    text-align: center;
  }

  .map {
    width: 100%;
    padding-bottom: 75%;
    margin-bottom: 1rem;
    background-color: $gray-200;
  }

  .contact-info {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      padding: 25px 0 25px 75px;
      background-position: top left;
      background-repeat: no-repeat;
      background-size: 70px 70px;
      min-height: 75px;
      &.timetable {
        background-image: url(../../assets/images/ico-contact-clock-equifax.png);
      }
      &.chat {
        background-image: url(../../assets/images/ico-contact-chat-equifax.png);
      }
      &.email {
        background-image: url(../../assets/images/ico-contact-email-equifax.png);
        a {
          color: #000;
        }
      }
      &.details {
        background-image: url(../../assets/images/ico-contact-map-equifax.png);
      }
    }
  }

  .recupera-ya {
    width: 300px;
    display: block;
    margin: 0 auto;
    @media (min-width: 1024px) {
      width: 140px;
      margin: 0;
    }
    @media (min-width: 1200px) {
      width: 165px;
    }
  }

}

.contact-equifax {
  &.splash {
    @media (min-width: 1024px) {
      background: url(../../assets/images/bg-contact.jpg) no-repeat center bottom;
      background-size: cover;
      min-height: 300px;
    }
    text-align: center;
  }

  .map {
    width: 100%;
    padding-bottom: 75%;
    margin-bottom: 1rem;
    background-color: $gray-200;
  }

  .contact-info {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      padding: 25px 0 25px 75px;
      background-position: top left;
      background-repeat: no-repeat;
      background-size: 70px 70px;
      min-height: 75px;
      &.timetable {
        background-image: url(../../assets/images/ico-contact-clock-equifax.png);
      }
      &.chat {
        background-image: url(../../assets/images/ico-contact-chat-equifax.png);
      }
      &.email {
        background-image: url(../../assets/images/ico-contact-email-equifax.png);
        a {
          color: #000;
        }
      }
      &.details {
        background-image: url(../../assets/images/ico-contact-map-equifax.png);
      }
    }
  }

  .recupera-ya {
    width: 300px;
    display: block;
    margin: 0 auto;
    @media (min-width: 1024px) {
      width: 140px;
      margin: 0;
    }
    @media (min-width: 1200px) {
      width: 165px;
    }
  }

}

.contact-equifax {
  &.splash {
    @media (min-width: 1024px) {
      background: url(../../assets/images/bg-contact.jpg) no-repeat center bottom;
      background-size: cover;
      min-height: 300px;
    }
    text-align: center;
  }

  .map {
    width: 100%;
    padding-bottom: 75%;
    margin-bottom: 1rem;
    background-color: $gray-200;
  }

  .contact-info {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      padding: 25px 0 25px 75px;
      background-position: top left;
      background-repeat: no-repeat;
      background-size: 70px 70px;
      min-height: 75px;
      &.timetable {
        background-image: url(../../assets/images/ico-contact-clock-equifax.png);
      }
      &.chat {
        background-image: url(../../assets/images/ico-contact-chat-equifax.png);
      }
      &.email {
        background-image: url(../../assets/images/ico-contact-email-equifax.png);
        a {
          color: #000;
        }
      }
      &.details {
        background-image: url(../../assets/images/ico-contact-map-equifax.png);
      }
    }
  }

  .recupera-ya {
    width: 300px;
    display: block;
    margin: 0 auto;
    @media (min-width: 1024px) {
      width: 140px;
      margin: 0;
    }
    @media (min-width: 1200px) {
      width: 165px;
    }
  }

}

