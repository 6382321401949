.blog {
 .post-entry {
   padding-bottom: 3rem;
   margin-bottom: 3rem;
   border-bottom: 2px solid $primary;
   img {
     margin-bottom: 2rem;
     @media (min-width: 1024px) {
       margin-bottom: 0;
     }
   }
 }

  .post-title {
    color: $primary;
  }

  .post-content {
    padding-bottom: 2rem;
    img {
      margin: 2rem 0;
    }
    p {
      margin: 1rem 0 2rem 0;
    }
  }

  .aside {
    .recent-posts {
      color: $gray-800;
      text-align: center;
      font-size: 1rem;
      padding: 5px 0;
      margin-bottom: 2rem;
      border-top: 2px solid $primary;
      border-bottom: 2px solid $primary;
    }
    .post-entry {
      padding-bottom: 2rem;
      margin-bottom: 2rem;
      img {
        margin-bottom: 1rem;
      }
      .post-meta {
        margin-bottom: 0;
      }
    }
  }

  .post-meta {
    color: $gray-800;
    font-size: 0.925rem;
  }
}