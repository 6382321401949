.how-it-works {
  .spacer div {
    border-top: 2px solid $primary;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .img-step {
    @media (max-width: 768px) {
      margin-bottom: 1rem;
    }
  }
  .btn-primary {
    @media (max-width: 768px) {
      margin-bottom: 1rem;
    }
  }
  .btn-primary-equifax {
    background-color: #9f1b32;
    font-weight: bold;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

}

.new-btn-efx {
  background-color: #9f1b32;
  font-weight: bold;
  font-size: 1.4rem;
  color: #ffffff;
  margin: 0 !important;
  padding: 15px 25px;
  border-radius: 40px;
  border: none;
}

.how-it-works-equifax {
  .spacer div {
    border-top: 2px solid #9f1b32;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .img-step {
    @media (max-width: 768px) {
      margin-bottom: 1rem;
    }
  }
  .btn-primary {
    @media (max-width: 768px) {
      margin-bottom: 1rem;
    }
  }
  .btn-primary-equifax {
    background-color: #9f1b32;
    font-weight: bold;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

}
