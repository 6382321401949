.navbar-collapse {
  @media (min-width: 1200px) {
    justify-content: flex-end;
  }

  &.show {
    .topbar {
      display: block;
      background-color: #000000;
      text-align: center;
      color: #fff;
      margin-bottom: 0.5rem;

      a {
        color: #fff;
        padding: 0 0.15rem;
      }
    }

    .topbar-equifax {
      display: block;
      background-color: transparent;
      text-align: center;
      color: #fff;
      margin-bottom: 0.5rem;

      a {
        color: #fff;
        padding: 0 0.15rem;
      }
    }
  }
}

.navbar-collapse-equifax {
  @media (min-width: 1200px) {
    justify-content: flex-end;
  }

  &.show {
    .topbar-equifax {
      display: block;
      background-color: #000000;
      text-align: center;
      color: #fff;
      margin-bottom: 0.5rem;

      a {
        color: #fff;
        padding: 0 0.15rem;
      }
    }
  }
}

.navbar-expand-xl .navbar-nav {
  @media (max-width: 1200px) {
    margin-top: 1rem;
  }

  a {
    border: none;
  }


  .separator {
    display: none;
    @media (min-width: 1200px) {
      padding: 0 6px;
      display: block;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 7px;
        height: 12px;
        border-right: 2px solid #000;
      }
    }
  }

  .separator-equifax {
    display: none;
    @media (min-width: 1200px) {
      padding: 0 6px;
      display: block;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 4px;
        height: 14px;
        border-right: 2px solid #ffffff;
      }
    }
  }

  .separator-equifax {
    display: none;
    @media (min-width: 1200px) {
      padding: 0 6px;
      display: block;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 4px;
        height: 14px;
        border-right: 2px solid #ffffff;
      }
    }
  }

  .separator-equifax {
    display: none;
    @media (min-width: 1200px) {
      padding: 0 6px;
      display: block;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 4px;
        height: 14px;
        border-right: 2px solid #ffffff;
      }
    }
  }

  .nav-item {
    @media (max-width: 1200px) {
      text-align: center;
    }
    .nav-link {
      color: #000;
      text-transform: uppercase;
      font-size: 0.9rem;
      @media (min-width: 1200px) {
        padding: 0 2px;
      }

    }
    .nav-link-equifax {
      color: #ffffff;
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: normal;
      @media (min-width: 1200px) {
        padding: 0 2px;
      }

    }
    .nav-link-equifax {
      color: #ffffff;
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: normal;
      @media (min-width: 1200px) {
        padding: 0 2px;
      }

    }
    .nav-link-equifax {
      color: #ffffff;
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: normal;
      @media (min-width: 1200px) {
        padding: 0 2px;
      }

    }
    &:last-child {
      @media (min-width: 1200px) {
        padding-right: 0 !important;
      }
    }
  }

  .nav-item.active .nav-link {
    color: #fff;
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
    &:hover {
      cursor: default;
    }
  }

  .nav-link {
    padding: 5px 0;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    &:hover {
      color: #fff !important;
    }
  }

}

.navbar-toggler {
  border: none;
}
