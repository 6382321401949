section {
  float: left;
  width: 100%;
}

.gutter-30 {
  margin-right: -30px;
  margin-left: -30px;

  > .col,
  > [class*="col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
}

#popup {
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1001;
  @media (max-width: 768px) {
    position: fixed;
  }
}

.content-popup {
  margin: 120px auto 0;
  position:relative;
  padding:10px;
  max-width:450px;
  min-height:250px;
  border-radius:4px;
  background-color:#FFFFFF;
  box-shadow: 0 2px 5px #666666;
}

.content-popup h2 {
  color:#48484B;
  border-bottom: 1px solid #48484B;
  margin-top: 0;
  padding-bottom: 4px;
}

.popup-overlay {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;
  display:none;
  background-color: #777777;
  cursor: pointer;
  opacity: 0.7;
}

.close {
  position: absolute;
  right: 15px;
}
.section-title {
  text-align: center;
  font-size: 1.5rem;
  @media (min-width: 768px) {
    font-size: 2rem;
  }
  span {
    display: inline-block;
    margin-bottom: 2rem;
    padding-bottom: 0.5rem;
    box-shadow: 0 4px 0 0 $primary;
  }
}

.vertical-space {
  justify-content:center;
  @media (min-width: 960px) {
    min-height: 200px;
    display: flex;
    align-items: center;
  }
}

.section-title-equifax {
  text-align: center;
  font-size: 1.5rem;
  vertical-align: middle;
  padding-top: 10px;
  color: #353535;
  @media (min-width: 768px) {
    font-size: 2rem;
  }
  span {
    display: inline-block;
    margin-bottom: 2rem;
    padding-bottom: 0.5rem;
    box-shadow: 0 4px 0 0 #9f1b32;
  }
  strong {
    color: #9f1b32;
  }
  @media (max-width: 650px) {
    margin-top: 3rem;
  }
}

.btn {
  font-weight: 300;
  width: 100%;
  @media (min-width: 1024px) {
    width: 250px;
  }
  padding: 10px 0 5px 0;
  text-transform: uppercase;
  transition: all 250ms ease-in-out;
  &:hover {
    background-color: $gray-600;
    color: #fff;
  }
}

.btn-primary {
  border: none;
  font-weight: 700;
  margin: 5px 0;
  &:hover {
    background-color: $gray-600;
    color: #fff;
  }
}

.btn-primary-black {
  border: none;
  font-weight: 700;
  margin: 5px 0;
  background-color: #000000;
  color: #ffffff;
  &:hover {
    background-color: $gray-600;
    color: #fff;
  }
}

.btn-primary-small {
  border: none;
  font-weight: 700;
  max-width: 180px;
  margin: 5px 0;
  background-color: $primary;
  &:hover {
    background-color: $gray-600;
    color: #fff;
  }
}

.btn-primary-small-equifax {
  border: none;
  font-weight: 700;
  max-width: 180px;
  margin: 5px 0;
  background-color: #9f1b32;
  color: #ffffff;
  &:hover {
    background-color: $gray-600;
    color: #fff;
  }
}

.btn-primary-small-equifax {
  border: none;
  font-weight: 700;
  max-width: 180px;
  margin: 5px 0;
  background-color: #9f1b32;
  color: #ffffff;
  &:hover {
    background-color: $gray-600;
    color: #fff;
  }
}

.btn-primary-small-equifax {
  border: none;
  font-weight: 700;
  max-width: 180px;
  margin: 5px 0;
  background-color: #9f1b32;
  color: #ffffff;
  &:hover {
    background-color: $gray-600;
    color: #fff;
  }
}
.btn-black-small {
  border: none;
  font-weight: 700;
  max-width: 180px;
  margin: 5px 0;
  background-color: #000000;
  color: #ffffff;
  &:hover {
    background-color: $gray-600;
    color: #fff;
  }
}

.btn-secondary {
  border: none;
  margin-bottom: 4px;
  color: $gray-900;
  background-color: $gray-300;
  &:hover {
    background-color: $gray-600;
    color: #fff;
  }
}

.lead {
  font-size: 1.1rem;
}

p > a, li > a {
  color: $body-color;
  border-bottom: 1px solid #000;
  text-decoration: none;
  &:hover {
    border-color: $primary;
    text-decoration: none;
  }
}

b, strong {
  font-weight: 700;
}

.arrows li {
  list-style: none;
  padding-left: 30px;
  position: relative;
  &:before {
    position: absolute;
    left: -30px;
    content: "";
    display: inline-block;
    width: 25px;
    height: 20px;
    background: url(../../assets/images/ico-arrow.png) no-repeat center 4px;
    background-size: 23px 16px;
  }
}

.none li {
  list-style: none;
  padding-left: 0px;
  position: relative;
  a {
    color: #9f1b32;
  }
}

.link-equifx-white{
  color: #ffffff;
  &:hover{
    color: #ffffff;
  }
}

.link-equifx-primary{
  color: #9f1b32;
  text-decoration: none;
  &:hover{
    color: #9f1b32;
    text-decoration: none;
  }
}

.none li {
  list-style: none;
  padding-left: 0px;
  position: relative;
  a {
    color: #9f1b32;
  }
}

.link-equifx-white{
  color: #ffffff;
  &:hover{
    color: #ffffff;
  }
}

.link-equifx-primary{
  color: #9f1b32;
  text-decoration: none;
  &:hover{
    color: #9f1b32;
    text-decoration: none;
  }
}

.none li {
  list-style: none;
  padding-left: 0px;
  position: relative;
  a {
    color: #9f1b32;
  }
}

.link-equifx-white{
  color: #ffffff;
  &:hover{
    color: #ffffff;
  }
}

.link-equifx-primary{
  color: #9f1b32;
  text-decoration: none;
  &:hover{
    color: #9f1b32;
    text-decoration: none;
  }
}

.staff {
  figure {
    display: block;
    margin: 0 auto;
    background-color: $gray-300;
    border: 2px solid $gray-300;
    margin-bottom: 1rem;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    img {
      border-radius: 50%;
      width: 150px;
      height: 150px;
    }
  }
  .name {
    display: inline-block;
    margin-bottom: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 1rem;
    span {
      font-weight: 300;
    }
    &:after {
      content: "";
      display: block;
      margin: 0.5rem auto 0 auto;
      width: 60px;
      border-bottom: 3px solid $primary;
    }
  }

  .name-equifax {
    display: inline-block;
    margin-bottom: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 1rem;
    span {
      font-weight: 300;
    }
    &:after {
      content: "";
      display: block;
      margin: 0.5rem auto 0 auto;
      width: 60px;
      border-bottom: 3px solid #9f1b32;
    }
  }

  p {
    font-size: 0.9rem;
  }
}

.form-control {
  border-radius: 0;
  background-color: $gray-200;
  border-color: $gray-200 !important;
}

.registrate{
  background-color:black;
  color:#FBD802;
  position: relative;
  bottom: 70px;
  right: 143px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 24px;
  padding: 12px 24px;
  cursor: pointer;
  width: 408px;
  border-radius: 1px;
  text-align: center;
}

.registrate-mobile{
  background-color:black;
  color:#FBD802;
  position: relative;
  top: 34px;
  left: 71px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 12px;
  padding: 12px 24px;
  cursor: pointer;
  width: 167px;
  border-radius: 1px;
  text-align: center;
}

.registrate-equifax{
  background-color:black;
  color:#ffffff;
  position: relative;
  bottom: 70px;
  right: 143px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 24px;
  padding: 12px 24px;
  cursor: pointer;
  width: 408px;
  border-radius: 1px;
  text-align: center;
}

.registrate-equifax-mobile{
  background-color:black;
  color:#ffffff;
  position: relative;
  top: 34px;
  left: 71px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 12px;
  padding: 12px 24px;
  cursor: pointer;
  width: 167px;
  border-radius: 1px;
  text-align: center;
}

.registrate-equifax{
  background-color:black;
  color:#ffffff;
  position: relative;
  bottom: 70px;
  right: 143px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 24px;
  padding: 12px 24px;
  cursor: pointer;
  width: 408px;
  border-radius: 1px;
  text-align: center;
}

.registrate-equifax-mobile{
  background-color:black;
  color:#ffffff;
  position: relative;
  top: 34px;
  left: 71px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 12px;
  padding: 12px 24px;
  cursor: pointer;
  width: 167px;
  border-radius: 1px;
  text-align: center;
}

.registrate-equifax{
  background-color:black;
  color:#ffffff;
  position: relative;
  bottom: 70px;
  right: 143px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 24px;
  padding: 12px 24px;
  cursor: pointer;
  width: 408px;
  border-radius: 1px;
  text-align: center;
}

.registrate-equifax-mobile{
  background-color:black;
  color:#ffffff;
  position: relative;
  top: 34px;
  left: 71px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 12px;
  padding: 12px 24px;
  cursor: pointer;
  width: 167px;
  border-radius: 1px;
  text-align: center;
}

.floating-recupera-ya {
  display: none;
  position: fixed;
  right: 0%;
  width: 150px;
  height: 150px;
  background: url("../../assets/images/recupera-ya.png") no-repeat center center;
  background-size: 95%;
  transition: all 50ms ease-in-out;
  @media (min-width: 1024px) {
    display: block;
    top: 10%;
  }
  @media (min-width: 1200px) {
    top: 15%;
  }
  &:hover {
    background-size: 100%;
  }
}

.floating-call-free {
  display: none;
  position: fixed;
  left: 0;
  width: 230px;
  height: 150px;
  background: url("../../assets/images/call-free.png") no-repeat center center;
  background-size: 95%;
  transition: all 50ms ease-in-out;
  @media (min-width: 1570px) {
    display: block;
    bottom: 5%;
  }
  &:hover {
    background-size: 100%;
  }
}

.form-check {
  padding-top: 3px;
  a {
    color: #000;
  }
}