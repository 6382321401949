.panelcontrol {

  .boxdatos {
    @media (min-width: 1024px) {
      min-height: 220px;
    }
    text-align: center;
    cursor: pointer;
    margin-bottom: 30px;
    background-color: #f2f2f2;
    transition: all .25s ease;

    .boxdatos-principal {
      padding-top: 20px;
      border-bottom: 1px solid #CCCCCC;

      .principal-title {
        font-size: 1.5rem;
        font-weight: lighter;
      }

      .principal-number {
        font-size: 2.2rem;
        font-weight: normal;
      }
    }

    .boxdatos-secondary {
      display: flex;
      width: 100%;

      .left {
        padding-top: 20px;
        width: 50%;
        border-right: 1px solid #CCCCCC;
      }

      .right {
        padding-top: 20px;
        width: 50%;
      }

      .secondary-title {
        font-size: 1.1rem;
        font-weight: lighter;
      }

      .secondary-number {
        font-size: 1.3rem;
        font-weight: lighter;
      }

    }
  }

  .boxdatos.active {
    background-color: #fed803;

    .boxdatos-principal {
      border-bottom: 1px solid #000000;
    }

    .boxdatos-secondary {
      display: flex;
      width: 100%;

      .left {
        border-right: 1px solid #000000;
      }

    }

  }

  .boxdatos.equifax.active {
    background-color: #9f1b32;

    .principal-number , .secondary-number {
      color: #FFFFFF;
    }

    .boxdatos-principal.equifax {
      border-bottom: 1px solid #FFFFFF;
      color: #FFFFFF;
    }

    .boxdatos-secondary.equifax {
      display: flex;
      width: 100%;
      color: #FFFFFF;

      .left {
        border-right: 1px solid #FFFFFF;
      }

    }

  }

  .boxdatos:hover {
    background-color: #fed803;
    transform: scale(1.05, 1.05);

    .boxdatos-principal {
      border-bottom: 1px solid #000000;
    }

    .boxdatos-secondary {
      display: flex;
      width: 100%;

      .left {
        border-right: 1px solid #000000;
      }

    }
  }

  .boxdatos.equifax:hover {
    background-color: #9f1b32;
    transform: scale(1.05, 1.05);

    .principal-number , .secondary-number {
      color: #FFFFFF;
    }

    .boxdatos-principal.equifax {
      border-bottom: 1px solid #FFFFFF;
      color: #FFFFFF;
    }

    .boxdatos-secondary.equifax {
      display: flex;
      width: 100%;
      color: #FFFFFF;

      .left {
        border-right: 1px solid #FFFFFF;
      }

    }
  }

}