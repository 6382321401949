.who-we-are {
  @media (min-width: 1024px) {
    background: url(../../assets/images/bg-who-we-are.jpg) no-repeat center bottom;
  }

  .logo-ka-recovering {
    display: block;
    margin: 0 auto;
    width: 210px;
    height: auto;
  }
}

.who-we-are-equifax {
  @media (min-width: 1024px) {
    //background: url(../../assets/images/bg-who-we-are.jpg) no-repeat center bottom;
  }

  .logo-ka-recovering {
    display: block;
    margin: 0 auto;
    width: 210px;
    height: auto;
  }
}

.team.staff {
  figure, img {
    @media (max-width: 767px) {
      width: 200px;
      height: 200px;
    }
    @media (min-width: 1024px) {
      width: 250px;
      height: 250px;
    }
  }
  .name {
    font-size: 1.5rem;
    margin-bottom: 3rem;
    span {
      font-size: 1rem;
    }
  }
}