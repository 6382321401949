.footer {
  background-color: $primary;
  font-size: 0.9rem;
  p {
    @media (min-width: 1024px) {
      margin-bottom: 0;
    }
  }

  a {
    color: #000;
    border-bottom: none;
  }

  .site-logo {
    width: 150px;
    height: auto;
  }

  .payments-logos {
    margin: 0 auto;
    max-width: 150px;
    @media (min-width: 1024px) {
      margin: 0;
      float: right;
      max-width: 200px;
    }
  }
}

.footer-equifax {
  background-color: #9f1b32;
  font-size: 0.9rem;
  p {
    color: #ffffff;
    @media (min-width: 1024px) {
      margin-bottom: 0;
    }
  }

  a {
    color: #ffffff;
    border-bottom: none;
  }

  .site-logo {
    width: 150px;
    height: auto;
  }

  .payments-logos {
    margin: 0 auto;
    max-width: 150px;
    @media (min-width: 1024px) {
      margin: 0;
      float: right;
      max-width: 200px;
    }
  }
}

.footer-equifax {
  background-color: #9f1b32;
  font-size: 0.9rem;
  p {
    color: #ffffff;
    @media (min-width: 1024px) {
      margin-bottom: 0;
    }
  }

  a {
    color: #ffffff;
    border-bottom: none;
  }

  .site-logo {
    width: 150px;
    height: auto;
  }

  .payments-logos {
    margin: 0 auto;
    max-width: 150px;
    @media (min-width: 1024px) {
      margin: 0;
      float: right;
      max-width: 200px;
    }
  }
}

.footer-equifax {
  background-color: #9f1b32;
  font-size: 0.9rem;
  p {
    color: #ffffff;
    @media (min-width: 1024px) {
      margin-bottom: 0;
    }
  }

  a {
    color: #ffffff;
    border-bottom: none;
  }

  .site-logo {
    width: 150px;
    height: auto;
  }

  .payments-logos {
    margin: 0 auto;
    max-width: 150px;
    @media (min-width: 1024px) {
      margin: 0;
      float: right;
      max-width: 200px;
    }
  }
}