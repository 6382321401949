.user-expedientes {
  &.intro {
    @media (min-width: 1024px) {
      background: url(../../assets/images/bg-new-solution.png) no-repeat center bottom;
    }
  }

  .boxnav {
    border: 0px;
    text-align: center;
    margin-bottom: 1rem;
    @media (min-width: 1024px) {
      min-height: 300px;
    }

    .boxnav-header {
      position: relative;
      background-color: #f2f2f2;
      padding: 5px;
      min-height: 300px;

      .circle{
        position: absolute;
        content: "";
        top: 1rem;
        right: 1.5rem;
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        background-color: #fed803;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        .counter {
          margin: 0;
          padding: 0;
          font-weight: bold;
        }
      }
      .circle-equifax{
        background-color: #9f1b32;
        .counter-equifax {
          color: #FFFFFF;
        }
      }

      img {
        width: 80px;
        height: auto;
        margin: 30px;
      }

      .boxnav-title {
        margin: 0;
        font-size: 1.3rem;
        font-weight: normal;
        padding: 0 15px;
        @media (min-width: 1024px) {
          padding: 0;
        }
      }
    }

    p {
      margin: 0;
      padding: 15px 20px;
      @media (min-width: 1024px) {
        padding: 15px 10px;
      }
    }
  }

  .boxnew {
    border: 0px;
    text-align: center;
    margin-bottom: 1rem;
    @media (min-width: 1024px) {
      min-height: 160px;
    }

    .boxnew-header {
      background-color: $primary;
      display: flex;
      padding: 5px;
      min-height: 80px;
      align-items: center;

      img {
        width: 80px;
        height: auto;
        margin: 30px;
        @media (max-width: 900px) {
          display: none;
        }
      }

      .linea {
        border-right: 1px solid;
        border-color: #000000;
        @media (max-width: 900px) {
          display: none;
        }
      }
      .titular {
        font-size: 1.6rem;
        line-height: 1.8rem;
        font-weight: normal;
      }
      .boxnew-title {
        margin: 0;
        font-size: 1.2rem;
        line-height: 1.4rem;
        font-weight: normal;
        padding: 0 15px;
        @media (min-width: 1024px) {
          padding: 0;
        }
      }
    }
    p {
      margin: 0;
      padding: 15px 20px;
      @media (min-width: 1024px) {
        padding: 15px 10px;
      }
    }
  }

  .boxnew-equifax {
    border: 0px;
    text-align: center;
    margin-bottom: 1rem;
    @media (min-width: 1024px) {
      min-height: 160px;
    }

    .boxnew-header-equifax {
      background-color: #9f1b32;
      display: flex;
      padding: 5px;
      min-height: 80px;
      align-items: center;
      img {
        width: 80px;
        height: auto;
        margin: 30px;
        @media (max-width: 900px) {
          display: none;
        }
      }

      .linea-equifax {
        border-right: 1px solid;
        border-color: #ffffff;
        @media (max-width: 900px) {
          display: none;
        }
      }
      .titular-equifax {
        color: #ffffff;
        font-size: 1.6rem;
        font-weight: normal;
      }
      .boxnew-title-equifax {
        margin: 0;
        font-size: 1.2rem;
        font-weight: normal;
        padding: 0 15px;
        color: #ffffff;
        @media (min-width: 1024px) {
          padding: 0;
        }
      }
    }
    p {
      margin: 0;
      padding: 15px 20px;
      @media (min-width: 1024px) {
        padding: 15px 10px;
      }
    }
  }

  .boxalert {
    border: 0px;
    padding: 5px;
    margin-bottom: 1rem;
    display: flex;
    background-color: $primary;
    border-radius: 5px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
    align-items: center;
    @media (min-width: 1024px) {
      min-height: 120px;
    }

    .linea {
      border-right: 1px solid;
      border-color: #000000;
    }

    .titular {
      font-size: 1.6rem;
      font-weight: normal;
    }

    .cta {
      display: block;
      margin: auto;
    }

    img {
      width: 62px;
      height: auto;
      display: block;
      margin: auto;
    }

    p {
      margin: 0;
      padding: 15px 20px;
      @media (min-width: 1024px) {
        padding: 15px 10px;
      }
    }
  }

  .boxalert-equifax {
    border: 0px;
    padding: 5px;
    margin-bottom: 1rem;
    display: flex;
    background-color: #9f1b32;
    border-radius: 5px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
    align-items: center;
    @media (min-width: 1024px) {
      min-height: 120px;
    }

    .linea {
      border-right: 1px solid;
      border-color: #ffffff;
    }

    .titular {
      font-size: 1.6rem;
      font-weight: normal;
      color: #ffffff;
    }

    .cta {
      display: block;
      margin: auto;
    }

    img {
      width: 62px;
      height: auto;
      display: block;
      margin: auto;
    }

    p {
      margin: 0;
      padding: 15px 20px;
      color: #ffffff;
      @media (min-width: 1024px) {
        padding: 15px 10px;
      }
    }
  }
}